.navigation-bar {
  position: relative;
  .btn-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 217, 217, 0.2);
    height: 8rem;
    z-index: 999;
    margin-top: 5rem;
  }

  .btn-area {
    padding: 0.5rem 2rem;
    margin-right: 2rem;
    border-radius: 2rem;

    span {
      // font-size: 1.8rem;
      // font-weight: bold;
      color: #ffffff;
    }
  }

  .btn-area:hover {
    background-color: #ffffff;

    span {
      color: #1e9ad6;
    }
  }
  .card {
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    margin-bottom: 40rem;
    padding: 4rem;
  }

  .card img {
    width: 8rem;
  }
  @media (max-width: 768px) {
    .card {
      margin-bottom: 4rem;
      padding: 4rem;
    }

    .btn-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: rgba(217, 217, 217, 0.2);
      height: 80px;
      z-index: 999;
      margin-bottom: 16px;
    }
  }
}
