/* Links */
.link {
  .faLink {
    font-weight: 900;
    font-size: 1.7em;
  }

  .ItemArea {
    box-shadow: 0 0 1px #000000;
    margin-bottom: 1.6rem;
    background-color: #ffffff;
    border-radius: 7px;
  }

  .BtnArea {
    padding: 1rem 2rem;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    // width: 13.4rem;
    background-color: #1e9ad6;

    span {
      color: #ffffff;
    }
  }
}
