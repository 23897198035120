.expohall-booth-page {
  position: relative;
  .bgImg {
    width: 100%;
    background-color: #c2dbea;
  }
  .chat-bubble div span {
    max-height: 74px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .bottom-area {
    box-shadow: 0 0.1rem 0.4rem 0 rgba(197, 182, 168, 0.92);
    margin-bottom: 30rem;
    margin-top: 1rem;
  }
  .bottom-area img {
    width: 8rem;
  }
  .breadcrumbs {
    position: absolute;
    top: 80px;
    left: 40px;
    z-index: 999;
  }
  .peaple {
    position: absolute;
    width: 8%;
    //height: '36%',
    top: 55%;
    right: 17%;
  }
  @media (min-width: 300px) and (max-width: 768px) {
    .bottom-area {
      margin-bottom: 4rem;
      padding: 4rem;
    }
    .tooltipsterBox {
      pointer-events: auto;
      margin: 0 auto;
      width: 380px;
      // height: 168px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 7px;
      color: #1e9ad6;
      padding: 3rem 3rem;
      z-index: 99;
      border: 1px solid #dee2e6;
      // position: absolute;
      // left: 0;
      // right: 0;
    }
    .peaple {
      top: 49% !important;
      width: 9.5% !important;
      right: 14% !important;
    }
    .mobile-box {
      height: 20px;
    }
  }
  @media (min-width: 768px) {
    .tooltipsterBox {
      pointer-events: auto;
      margin: 0 auto;
      min-width: 280px;
      // top: 33%;
      bottom: 57%;
      right: 12.5%;
      max-width: 380px;
      max-height: 168px;
      box-sizing: border-box;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 7px;
      color: #1e9ad6;
      padding: 20px 20px;
      z-index: 99;
      border: 1px solid #dee2e6;
    }
    .tool-tips {
      right: -6%;
      top: 36%;
    }
    .triangleDown {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #ffffff;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -15px;
    }
    .mobile-box {
      height: 0;
    }
  }
  @media (max-width: 1280px) {
  }
  @media (min-width: 1280px) {
  }
  li {
    list-style-type: none;
  }
}
.ant-col.tooltipsterBox.center div span {
  max-height: 74px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
