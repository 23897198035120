.mySessions {
  padding: 10rem 30rem 20rem;
  background: #fff;
}
@media (max-width: 768px) {
  .mySessions {
    padding: 5rem 1rem 10rem;
  }
}
/* .mySessions .mySessionsBtn{
  background-color: #1E9AD6;
  border-color: #1E9AD6;
  color: #fff;
}
.mySessions .mySession{
  background-color: #1E9AD6;
  border-color: #1E9AD6;
  color: #fff
}
.tag{
  color: #fff;
  background-color: #1E9AD6;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  padding: .25em 0;
  width: 25px;
  border-radius: 48%;
  margin-left: 8px;
}
@media (max-width: 768px){
  .mySessions{
    padding: 5rem 1rem 10rem;
  }
  .fontMbs{
    font-size: 10px !important;
  }
  .tag{
    width: 0px;
    margin-left: 3px;
  }
  .antBtnRound {
    height: 32px !important;
    padding: 4px 12px !important;
    font-size: 10px !important;
  }
} */
