.result-list-page {
  position: relative;
  min-height: 66.2rem;
  .asi-big-title {
    font-weight: 500;
    font-size: 20px;
  }

  .ant-pagination-item-active {
    background: #1e9ad6;
  }

  .ant-pagination-item-active a {
    color: #ffffff;
  }

  .ant-pagination-item:hover {
    background: #d9d9d9;
    border-color: #d9d9d9;
  }

  .ant-pagination-item:hover a {
    color: #000000;
  }

  .ant-pagination-item-active:hover {
    background: #1e9ad6;
  }

  .ant-pagination-item-active:hover a {
    color: #ffffff;
  }

  .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination-next .ant-pagination-item-link:hover {
    background: #d9d9d9;
    border-color: #d9d9d9;
    color: #000000;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled .ant-pagination-item-link:hover,
  .ant-pagination-disabled:focus-visible {
    background: #ffffff;
    color: #d9d9d9;
  }

  .no-record {
    color: #666;
  }
}
