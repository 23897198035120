.JoinInfoTabPane {
  // margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  // margin-top: 2rem;
  height: 52rem;
  min-height: 55vh;
  padding-bottom: 20px;
  overflow-y: scroll;

  .profilePic {
    // width: 100%;
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #f2f5f7;
    vertical-align: middle;
  }

  .color {
    // color: #005c9b;
    color: #1e9ad6;
  }

  // .color2 {
  //   color: #0e3d74;
  // }

  .speakerName {
    // color: #004096;
    // font-weight: bold;
    font-size: 2rem;
    // margin-top: 2rem;
    // cursor: pointer;
  }

  .speakerJobTitle {
    font-size: 1.8rem;
    font-weight: 400;
    color: #6c757d;
    line-height: 1.8em;
    // cursor: pointer;
  }
  .type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;
    width: fit-content;
  }
  .type-live {
    // width: 66px;
    height: 24px;
    background: #ebfafa;
    color: #1e9ad6;
  }
  .type-rec {
    background: #dfe6e9;
    border-radius: 7px;
    // width: 100px;
    height: 24px;
  }
}
