.loginHeader {
  background-color: #1e9ad6;
  color: #fff;
  padding: 5rem 0 4rem;
  position: relative;
  text-align: center;
}
.registerHeader {
  background-color: #1e9ad6;
  color: #fff;
  padding: 5rem 0 4rem;
  margin: 0 !important;
  position: relative;
  text-align: center;
}
.title {
  font-weight: 700;
  font-size: 5rem;
  color: #fff;
  border-bottom: 2px solid #1e9ad6;
}
.titleSub {
  font-weight: 700;
  font-size: 4rem;
}
.innerForm {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 5rem;
  margin-top: 5rem;
}
.forgot {
  color: #1e9ad6;
  font-weight: bold;
  margin: 5rem 0 2rem;
}
.btnPrimary {
  background-color: #1e9ad6 !important;
  border-color: #1e9ad6 !important;
  font-weight: bold;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}
.headerArea {
  background: #fff;
}
.mainTitle {
  font-weight: 700;
  font-size: 3.5rem;
  /* color: #1E9AD6; */
  /* border-bottom: 2px solid #1E9AD6; */
}
.secondTitle {
  font-weight: 500;
  font-size: 3rem;
  color: #1e9ad6;
}
.threeTitle {
  font-weight: 500;
  font-size: 3rem;
  color: #000000;
}
.back {
  position: absolute;
  top: 15px;
  left: 15rem;
  font-size: 2.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: lighter;
}
.registerWrap {
  background-color: #f5f5f5;
}
.registerForm {
  background-color: #fff;
  width: 80%;
  padding: 5rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.languageLayout {
  position: absolute;
  right: 5rem;
  bottom: 2rem;
}
