.TabSpeakerPaneItem {
  padding: 1rem 2rem;

  h4 {
    // font-weight: bolder;
    // font-size: 3.3rem;
    font-size: 20px;
    line-height: 3em;
  }

  .item {
    position: relative;
    // box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    margin-bottom: 5rem;
    border-radius: 7px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    // border-radius: .25rem;
    padding: 4rem;
    // border-right: 8px solid #1E9AD6;
    line-height: 2em;
    // border-top-right-radius: .5rem;
    // border-bottom-right-radius: .5rem;
    .type {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 10px;
    }
    .type-live {
      // width: 66px;
      height: 24px;
      background: #ebfafa;
      color: #1e9ad6;
    }
    .type-rec {
      background: #dfe6e9;
      border-radius: 7px;
      // width: 100px;
      height: 24px;
    }
    .date-start-end {
      margin-top: 5px;
      color: #1e9ad6;
      font-size: 20px;
      // line-height: 2em;
    }

    .play {
      background-color: #28a745;
      border-color: #28a745;
    }

    .live {
      background-color: #17a2b8;
      border-color: #17a2b8;
    }

    .ended {
      background-color: #999;
      border-color: #999;
    }
    .RiHeartFill {
      position: absolute;
      right: 25px;
      top: 24px;
    }
  }

  .agendaBtn {
    background-color: #1e9ad6;
    border-color: #1e9ad6;
    color: #fff;
    margin-bottom: 1.7rem;
  }

  .details:hover {
    background-color: #1e9ad6;
    border-color: #1e9ad6;
    color: #fff;
  }

  .removeBtn {
    color: #dc3545;
    border-color: #dc3545;
  }

  .removeBtn:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .right-border {
    border-right: 1px solid #dee2e6;
  }

  // .ant-timeline.ant-timeline-label .ant-timeline-item-label {
  //   width: calc(8% - 12px);
  // }
  .ant-timeline-item-tail {
    border-left-color: #dfe6e9;
  }
  .ant-timeline-item-last > .ant-timeline-item-tail {
    display: block;
  }

  // .ant-timeline.ant-timeline-label .ant-timeline-item-head {
  //   left: 8%;
  // }

  // .ant-timeline-item-content {
  //   left: calc(8% - 4px) !important;
  //   width: auto !important;
  // }

  // // .ant-timeline-item-head-blue {}

  // .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  //   border-left-style: solid;
  //   left: 8%;
  // }

  // .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-head {
  //   left: 7.5%;
  // }

  // .ant-timeline-item-pending .ant-timeline-item-head {
  //   left: 8%;
  // }

  // .ant-timeline.ant-timeline-label .ant-timeline-item-last .ant-timeline-item-head {
  //   margin-left: 0;
  // }
}

@media (max-width: 768px) {
  .antBtnRound {
    height: 32px !important;
    padding: 4px 12px !important;
    font-size: 10px !important;
  }
  .TabSpeakerPaneItem {
    padding: 0;
  }
}
