.category-filter {
  position: sticky;
  top: 8rem;
  margin: 2rem 2rem 0 0;
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 7px;
  text-align: left;
  border: 1px solid #dfe6e9;

  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 10px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 3px;
  }

  .ant-btn {
    color: #1e9ad6;
    background: #ebfafa;
    border-color: #ebfafa;
    box-shadow: none;
    margin-bottom: 32px;
  }

  .filter-t {
    font-size: 2.5rem;
    font-weight: bold;
    color: #212529;
    line-height: 2em;
    margin-top: 1em;
  }

  .filter-title {
    cursor: pointer;
    font-weight: 500;
    padding: 1rem 0;
    vertical-align: middle;
    // word-spacing: 1rem;
    font-size: 14px;
    color: #636e72;
  }

  .mL05 {
    margin-left: 0.5rem;
  }

  .sub-title {
    line-height: 2em;
    cursor: pointer;
    font-size: 14px;
    color: #2d3436;
  }

  .selected {
    color: #1e9ad6;
    font-weight: bold;

    .icon-bc {
      color: #1e9ad6;
    }
  }

  .icon-bc {
    color: #b2bec3;
    // font-size: 12px;
  }

  .icon-f {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .category-filter {
    margin-right: 14px;
  }
  .filter-item-text {
    width: 25rem;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .category-filter {
    margin-right: 0;
  }
  .filter-item-text {
    width: 80%;
    font-weight: 500;
  }
}
