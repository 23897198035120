.result-type-page {
  background-color: #ffffff;
  border-radius: 7px;
  .top-area {
    padding: 1.5rem 2rem;
    background: #1e9ad6;
    color: #ffffff;
  }
  .cell {
    padding: 1.5rem 2rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.125);
    width: 100%;
  }
  .cell:hover {
    background: #f8f9fa;
  }
  .cell:active {
    background: rgba(0, 0, 0, 0.125);
  }
  .asi-selected {
    background: #f7f7f7;
  }
}
