.tabCotent {
  padding: 1rem 2rem;

  h4 {
    font-weight: bolder;
    font-size: 3.5rem;
    line-height: 3em;
  }

  .item {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 4rem;
    border-right: 8px solid #1e9ad6;
    line-height: 2em;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .play {
      background-color: #28a745;
      border-color: #28a745;
    }

    .live {
      background-color: #17a2b8;
      border-color: #17a2b8;
    }

    .ended {
      background-color: #999;
      border-color: #999;
    }
  }

  .agendaBtn {
    background-color: #1e9ad6;
    border-color: #1e9ad6;
    color: #fff;
    margin-bottom: 1.7rem;
  }

  .details:hover {
    background-color: #1e9ad6;
    border-color: #1e9ad6;
    color: #fff;
  }

  .removeBtn {
    color: #dc3545;
    border-color: #dc3545;
  }

  .removeBtn:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .right-border {
    border-right: 1px solid #dee2e6;
  }
}

@media (max-width: 768px) {
  .antBtnRound {
    height: 32px !important;
    padding: 4px 12px !important;
    font-size: 10px !important;
  }
}
