.myAgenda {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #495057;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #fff;
  }

  // .ant-tabs-tab-btn {
  //     color: #1E9AD6;
  //     font-size: 15px;
  // }

  // .ant-tabs-ink-bar {
  //     background-color: #1E9AD6;
  //     height: 4px !important;
  // }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20rem;
  }

  @media (max-width: 768px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 20px;
    }

    .ant-tabs-tab-btn {
      // color: #1E9AD6;
      font-size: 14px;
    }
  }
}

.agenda,
.myAgenda {
  .ant-tabs > .ant-tabs-nav {
    background-color: #fff;
    border-radius: 5px;
    color: #b4b4b4;
    font-weight: 700;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: 0 0 0.25px currentcolor;
    color: #212529;
    .tap-index {
      background: #212529;
    }
  }

  .ant-tabs-ink-bar {
    background: #212529;
  }
  .tap-index {
    background: #b4b4b4;
    border-radius: 50%;
    font-size: 0.75em;
    font-weight: 700;
    color: #fff;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    color: #fff;
  }
}
