.categories-page {
  margin-top: 2rem;

  .list-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .border {
    border: 1px solid #dee2e6 !important;
    border-radius: 80px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: white;
    padding: 0 2rem;
    overflow: hidden;
  }
  .img-area {
    background-color: #ffffff;
    width: 100%;
    border-radius: 15px;
  }
  .recommend-product-img {
    width: 100%;
    height: 152px;
    object-fit: contain;
  }
  .recommend-bottom-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    // color: white;
    margin-top: 16px;
    // background-color: #1E9AD6;
    // background-image: linear-gradient(160deg, #1E9AD6 0%, #ffac80 100%);
    margin-bottom: 0;
  }
  .recommend-bottom-title span {
    word-break: break-word;
  }
  .recommend-bottom-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 40px;
    position: absolute;
    right: 30px;
  }
  .start-out-line {
    color: white;
  }
}
@media screen and (max-width: 767px) {
  .categories-page {
    margin-top: 32px;
  }
}
