.contact-header {
  .f16 {
    font-size: 1.6rem;
  }

  .react-btn {
    height: 40px;
    display: flex;
    align-items: center;
    // line-height: 7.8rem;
  }

  .drop-down {
    background: #1e9ad6;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.6rem;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;

    .search-title {
      color: white;
    }
  }

  .search {
    border: 0.1rem solid #495057;
  }

  .clear-btn {
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #1e9ad6;
    color: #1e9ad6;
  }

  .btn {
    padding: 0.5rem 2rem;
    border-radius: 4px;
    //border: 0.1rem solid #1E9AD6;
    //color: #1E9AD6;
  }

  .selected {
    background: #1e9ad6;
    color: #ffffff;
  }
}
