.business-matching-page {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #495057;
  }
  .ant-tabs-tab-btn {
    color: #1e9ad6;
    font-size: 1.5rem;
    width: 26rem;
    text-align: center;
  }
  .ant-tabs-ink-bar {
    background-color: #1e9ad6;
    height: 0.4rem !important;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    //margin: 0 0 0 30rem;
  }
  @media (max-width: 768px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 2rem;
    }
    .ant-tabs-tab-btn {
      // color: #1E9AD6;
      font-size: 14px;
    }
  }
}
